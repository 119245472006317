<script setup lang="ts">
import BtnPrimary from '@/components/ui/BtnPrimary.vue';

const config = useRuntimeConfig();
// const contactEmail = config.public.contactEmail;
const contactEmail = 'info@venturecake.eu';

const handleContactUs = () => {
  window.open(`mailto:${contactEmail}`, '_blank');
};
</script>
<template>
  <div
    class="footer-container border-top-secondary d-flex justify-space-between"
  >
    <div class="footer-left border-right-secondary">
      <span class="text-soft-teal"
        >Copyright © 2024 Venturecake powered by
      </span>
      <a href="http://wexelerate.com/" target="_blank">weXelerate</a>
    </div>

    <div class="footer-right d-flex justify-center">
      <span><nuxt-link to="/impressum">Impressum</nuxt-link></span>
      <span><nuxt-link to="/privacy-policy">Privacy policy</nuxt-link></span>
      <span class="get-in-touch-desktop"
        ><a :href="`mailto:${contactEmail}`">Let’s get in touch!</a></span
      >
    </div>

    <div class="get-in-touch-mobile">
      <BtnPrimary
        title="Let’s get in touch!"
        variant="outlined"
        @callback="handleContactUs"
      />
    </div>
  </div>
</template>

<style scoped lang="scss">
.footer-container {
  padding: 0 70px;
  font-size: 15px;
  font-weight: 500;
  line-height: 24px;
  background-color: rgb(var(--v-theme-primary));
  color: rgb(var(--v-theme-white));
}
.footer-left {
  width: 55%;
  padding: 33px 70px 33px 0;
}
.footer-right {
  width: 45%;
  padding: 33px 0 33px 50px;
  gap: 0 60px;
}

.get-in-touch-mobile {
  display: none !important;
}

@media screen and (max-width: 1350px) {
  .footer-container {
    padding: 0 33px;
  }

  .footer-left {
    width: 48% !important;
    padding: 33px 33px 33px 0;
  }

  .footer-right {
    width: 52% !important;
    padding: 33px 0 33px 33px;
  }
}

@media screen and (min-width: 576px) and (max-width: 1024px) {
  .footer-container {
    padding: 30px 20px !important;
    display: flex !important;
    flex-direction: column-reverse !important;
    align-items: center !important;
    border-top: none !important;
  }

  .get-in-touch-mobile {
    display: block !important;
    max-width: 622px !important;
    width: 100% !important;

    :deep(.v-btn) {
      width: 100% !important;
    }
  }

  .get-in-touch-desktop {
    display: none !important;
  }

  .footer-right {
    width: 100% !important;
    padding: 0 !important;
    justify-content: center !important;
    gap: 60px;
    margin: 20px 0 !important;

    span {
      padding: 10px 0;
    }
  }

  .footer-left {
    width: 100% !important;
    padding: 0 60px !important;
    border-right: none !important;
    text-align: center !important;
  }
}

@media screen and (max-width: 576px) {
  .footer-container {
    padding: 30px 20px !important;
    display: flex !important;
    flex-direction: column-reverse !important;
    align-items: center !important;
    border-top: none !important;
  }

  .get-in-touch-mobile {
    display: block !important;
    width: 100% !important;

    :deep(.v-btn) {
      width: 100% !important;
    }
  }

  .get-in-touch-desktop {
    display: none !important;
  }

  .footer-right {
    width: 100% !important;
    padding: 0 !important;
    justify-content: center !important;
    gap: 60px;
    margin: 20px 0 !important;

    span {
      padding: 10px 0;
    }
  }

  .footer-left {
    width: 100% !important;
    padding: 0 60px !important;
    border-right: none !important;
    text-align: center !important;
  }
}
</style>

<script setup lang="ts">
import { ref, watch } from 'vue';
import { nextTick } from 'vue';
import HeaderLogo from '@/assets/images/svg/HeaderLogo.svg';
import BurgerIcon from '@/assets/images/svg/icons/ic_burger.svg';
import CloseIcon from '@/assets/images/svg/icons/ic_close.svg';

import { SectionIds } from '@/types/enums';
const config = useRuntimeConfig();

const isBurgerMenuOpen = ref(false);
// const contactEmail = config.public.contactEmail;
const contactEmail = 'info@venturecake.eu';

const onOpenBurgerMenu = () => {
  isBurgerMenuOpen.value = !isBurgerMenuOpen.value;
};

watch(isBurgerMenuOpen, (newValue) => {
  const menu = document.querySelector('.header-menu');
  if (menu) {
    if (newValue) {
      menu.classList.add('open');
      document.body.classList.add('no-scroll');
      document.body.style.position = 'fixed';
      document.body.style.width = '100%';
    } else {
      menu.classList.remove('open');
      document.body.classList.remove('no-scroll');
      document.body.style.position = '';
      document.body.style.width = '';
    }
  }
});

const handleScrollToSection = async (id: SectionIds) => {
  await navigateTo('/');
  nextTick(async () => {
    const element = document.getElementById(`${id}`);

    if (element) {
      const elementRect = element.getBoundingClientRect();
      const offset = window.scrollY + elementRect.top;

      const headerHeight = 90;

      const duration = 1200;
      const start = window.scrollY;
      const change = offset - headerHeight - start;
      const increment = 20;

      const animateScroll = (elapsedTime: number) => {
        elapsedTime += increment;
        const position = easeInOutQuad(elapsedTime, start, change, duration);
        window.scrollTo(0, position);
        if (elapsedTime < duration) {
          setTimeout(() => animateScroll(elapsedTime), increment);
        }
      };

      const easeInOutQuad = (t: number, b: number, c: number, d: number) => {
        t /= d / 2;
        if (t < 1) return (c / 2) * t * t + b;
        t--;
        return (-c / 2) * (t * (t - 2) - 1) + b;
      };

      animateScroll(0);
    }
  });
};

const handleClickMenuItem = (id: SectionIds) => {
  onOpenBurgerMenu();
  handleScrollToSection(id);
};
</script>

<template>
  <div class="header w-100 d-flex justify-space-between align-center">
    <div
      class="header-left d-flex w-50 h-100 border-right justify-space-between align-center"
    >
      <nuxt-link
        class="header-logo text-charcoal-blue w-35 h-100 ec-text d-flex align-center justify-center px-6"
        to="/"
        @click="handleScrollToSection(SectionIds.Home)"
      >
        <nuxt-img
          width="160"
          height="32"
          src="/images/HeaderLogoDesktop.png"
          alt="HeaderLogo"
          class="d-none d-md-block"
        />
        <nuxt-img
          width="190"
          height="38"
          src="/images/HeaderLogoMobile.png"
          alt="HeaderLogo"
          class="d-md-none"
        />
      </nuxt-link>

      <div class="header-burger-button d-block d-md-none">
        <button class="header-burger-button" @click="onOpenBurgerMenu">
          <component :is="isBurgerMenuOpen ? CloseIcon : BurgerIcon" />
        </button>
      </div>
      <div class="header-menu" :class="[{ open: isBurgerMenuOpen }]">
        <div class="menu-items d-flex flex-column align-center">
          <span
            class="menu-item-mobile cursor-pointer"
            @click="isBurgerMenuOpen = false"
          >
            <a :href="`mailto:${contactEmail}`">Contact us</a>
          </span>
          <span
            class="menu-item-mobile cursor-pointer"
            @click="handleClickMenuItem(SectionIds.OurPartners)"
          >
            Our partners
          </span>
          <span
            class="menu-item-mobile cursor-pointer"
            @click="handleClickMenuItem(SectionIds.About)"
          >
            How it works
          </span>
          <span
            class="menu-item-mobile cursor-pointer"
            @click="handleClickMenuItem(SectionIds.FAQ)"
          >
            FAQ
          </span>
        </div>

        <div class="menu-footer">
          <div class="d-flex flex-column align-center">
            <div class="menu-footer-items d-flex justify-space-between">
              <span class="menu-footer-item"
                ><a href="/impressum">Impressum</a></span
              >
              <span class="menu-footer-item"
                ><a href="/privacy-policy">Privacy policy</a></span
              >
            </div>
            <span class="text-soft-teal"
              >Copyright © 2024 Venturecake powered by</span
            >
            <a href="http://wexelerate.com/" target="_blank">weXelerate</a>
          </div>
        </div>
      </div>
    </div>
    <div
      class="header-right d-flex w-50 h-100 justify-space-between align-center"
    >
      <div
        class="border-right flex-1 h-100 d-flex align-center justify-space-around px-6"
      >
        <span
          class="menu-item"
          @click="handleScrollToSection(SectionIds.About)"
        >
          How it works
        </span>
        <span
          class="menu-item"
          @click="handleScrollToSection(SectionIds.OurPartners)"
        >
          Our partners
        </span>
        <span class="menu-item" @click="handleScrollToSection(SectionIds.FAQ)">
          FAQ
        </span>
      </div>
      <div
        class="w-35 h-100 d-flex align-center justify-center menu-item"
        @click="isBurgerMenuOpen = false"
      >
        <a :href="`mailto:${contactEmail}`">Contact us</a>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.header {
  min-height: 90px;
  padding: 0;
  color: rgb(var(--v-theme-white));
  background-color: rgb(var(--v-theme-primary));
  border-bottom: 1px solid rgb(var(--v-theme-secondary)) !important;
  z-index: 1000;
}

.w-35 {
  width: 35% !important;
}

.flex-1 {
  flex: 1;
}

.border-right {
  border-right: 1px solid rgb(var(--v-theme-secondary)) !important;
}

.header-burger-button {
  background-color: rgb(var(--v-theme-lime-green));
  border: none;
  width: 90px;
  height: 100% !important;
}

.header-menu {
  position: fixed;
  top: 90px;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  padding-top: 130px;
  background-color: rgb(var(--v-theme-primary));
  opacity: 0 !important;
  visibility: hidden !important;
  transition: opacity 0.3s ease, visibility 0.3s ease;

  &.open {
    opacity: 1 !important;
    visibility: visible !important;
  }
}

.menu-item {
  cursor: pointer;
  color: rgb(var(--v-theme-white));
  transition: color 0.3s ease;

  &:hover {
    color: rgb(var(--v-theme-primary-purple)) !important;
  }
}

.menu-item-mobile {
  cursor: pointer;
  font-size: 34px;
  font-weight: 800;
  line-height: 34px;
  color: rgb(var(--v-theme-white));
  text-transform: uppercase;
  margin-bottom: 30px;
  transition: color 0.3s ease;

  &:hover {
    color: rgb(var(--v-theme-primary-purple)) !important;
  }
}

a {
  color: rgb(var(--v-theme-white)) !important;
  text-decoration: none;
}

.menu-footer {
  padding: 30px 20px;
  position: fixed;
  bottom: 0px;
  left: 0;
  right: 0;

  .menu-footer-items {
    width: 100%;
    margin-bottom: 20px;
  }

  .menu-footer-item {
    cursor: pointer;
    padding: 10px 42px !important;
  }
}

@media screen and (min-width: 576px) and (max-width: 1024px) {
  .header-left {
    border-right: none !important;
    width: 100% !important;
    justify-content: space-between !important;

    .header-logo {
      border-right: none !important;
      width: auto !important;
    }
  }

  .header-right {
    display: none !important;
  }

  .header-menu {
    padding-top: 50% !important;
  }

  .menu-footer {
    .menu-footer-items {
      justify-content: center !important;
      align-items: center !important;
    }

    .menu-footer-item {
      padding: 10px 30px !important;
    }
  }
}

@media screen and (max-width: 576px) {
  .header-left {
    border-right: none !important;
    width: 100% !important;
    justify-content: space-between !important;

    .header-logo {
      border-right: none !important;
      width: auto !important;
    }
  }

  .header-right {
    display: none !important;
  }

  .menu-footer {
    .menu-footer-items {
      justify-content: center !important;
      align-items: center !important;
    }

    .menu-footer-item {
      padding: 10px 30px !important;
    }
  }
}

.no-scroll {
  overflow: hidden;
}
</style>
